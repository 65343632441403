// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-activity-page-tsx": () => import("./../../../src/templates/activity-page.tsx" /* webpackChunkName: "component---src-templates-activity-page-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-destination-activity-page-tsx": () => import("./../../../src/templates/destination-activity-page.tsx" /* webpackChunkName: "component---src-templates-destination-activity-page-tsx" */),
  "component---src-templates-destination-page-tsx": () => import("./../../../src/templates/destination-page.tsx" /* webpackChunkName: "component---src-templates-destination-page-tsx" */),
  "component---src-templates-faqs-page-tsx": () => import("./../../../src/templates/faqs-page.tsx" /* webpackChunkName: "component---src-templates-faqs-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-post-page-tsx": () => import("./../../../src/templates/post-page.tsx" /* webpackChunkName: "component---src-templates-post-page-tsx" */),
  "component---src-templates-simple-page-tsx": () => import("./../../../src/templates/simple-page.tsx" /* webpackChunkName: "component---src-templates-simple-page-tsx" */),
  "component---src-templates-tag-page-tsx": () => import("./../../../src/templates/tag-page.tsx" /* webpackChunkName: "component---src-templates-tag-page-tsx" */),
  "component---src-templates-tour-page-tsx": () => import("./../../../src/templates/tour-page.tsx" /* webpackChunkName: "component---src-templates-tour-page-tsx" */)
}

